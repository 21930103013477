<template>
  <div id="app">
    <div id="nav">
      <router-view v-wechat-title="titleName"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  data() {
    return {};
  },
  created() {
    // 获取当前语言
  },
  computed: {
    titleName() {
      return this.$route.meta.title;
    },
  },
  watch: {
    titleName(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #ffffff;
  height: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f7f7fa;
}

#nav {
  height: 100%;
}
</style>
